import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AppConfigService } from '@eucoms/common-components';
import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private appConfig: AppConfigService,
    private msalGuard: MsalGuard
    ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

      if(this.appConfig.config.bypassMSAL === "true") {
        return of(true);
      } else {
        return this.msalGuard.canActivate(route, state);
      }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(this.appConfig.config.bypassMSAL === "true") {
      return of(true);
    } else {
      this.msalGuard.canActivateChild(route, state);
    }
  }

  canLoad() {
    if(this.appConfig.config.bypassMSAL === "true") {
      return of(true);
    } else {
      return this.msalGuard.canLoad();
    }
  }

}
