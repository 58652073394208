import { AppConfigServiceBase } from '@eucoms/common-components';
import { Injectable } from '@angular/core';
import { EUWebConfig } from '@eucoms/common-components/lib/eu-core-services/app-config/eu-web-config';
import { from, Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SimpleAppConfigService extends AppConfigServiceBase {
  protected getBaseConfig(): Observable<EUWebConfig> {
    return from(
      // the values fetched are needed to configure the MsalInterceptorFactory.
      // That's why we can't use HttpClient.get() -- MsalInterceptor would be used *before* it is configured
      window.fetch(environment.configApiUrl)
        .then(r => r.json())
        .then(data => {
          console.log(data);

          let allRequired = true;

          // ensure that all required values are present
          allRequired = this.checkRequired("opCo", data?.opCo) && allRequired;
          allRequired = this.checkRequired("liehMiddlewareApiUrl", data?.liehMiddlewareApiUrl) && allRequired;
          allRequired = this.checkRequired("liehMiddlewareApiUrlFunctionCode", data?.liehMiddlewareApiUrlFunctionCode) && allRequired;
          allRequired = this.checkRequired("ssoClientId", data?.ssoClientId) && allRequired;
          allRequired = this.checkRequired("ssoLoginBaseUrl", data?.ssoLoginBaseUrl) && allRequired;
          allRequired = this.checkRequired("ssoTenantId", data?.ssoTenantId) && allRequired;
          allRequired = this.checkRequired("ssoLoginScope", data?.ssoLoginScope) && allRequired;
          allRequired = this.checkRequired("liehuIUrl", data?.ssoLoginScope) && allRequired;

          this.checkOptional("liehMiddlewareApiUrlScope", data?.liehMiddlewareApiUrlScope); //make required if liehMiddlewareApriUrl is protected.
          this.checkOptional("graphAuthUrl", data?.graphAuthUrl);
          this.checkOptional("graphAuthUrlScope", data?.graphAuthUrlScope);
          this.checkOptional("bypassMSAL", data?.bypassMSAL);

          if (allRequired) {
            console.log("All required config values were loaded.");
          } else {
            throw new Error("Missing config values!");
          }

          return data as EUWebConfig;
        })
    );
  }

  private checkRequired(fieldName: string, value: any) {
    if (value) {
      console.log(`Required config value '${fieldName}' is '${value}'.`);
      return true;
    } else {
      console.error(`Required config value '${fieldName}' is missing!`);
      return false;
    }
  }

  private checkOptional(fieldName: string, value: any) {
    if (value) {
      console.log(`Optional config value '${fieldName}' is '${value}'.`);
    } else {
      console.log(`Optional config value '${fieldName}' is missing.`);
    }
  }
}
