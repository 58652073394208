import { EnergyHubApiService } from '../energy-hub-api/energy-hub-api.service';
import { Injectable } from '@angular/core';
import { Account } from '@app/core/model/account.model';
import { GraphUser } from '@app/core/model/graph-user.model';

@Injectable({
  providedIn: 'root'
})
export class GraphUserService {

    constructor(private api: EnergyHubApiService) { }

    getCurrentUser() {
      return this.api.get<GraphUser>(`me`);
    }

  
}
