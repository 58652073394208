import { Component, Input, OnInit } from '@angular/core';
import { Device } from '@app/core/model/device.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-devices-list',
  templateUrl: './devices-list.component.html',
  styleUrls: ['./devices-list.component.scss']
})
export class DevicesListComponent implements OnInit {

  constructor() { }
  @Input() dataSource: Array<Device>;

  columnDefs = [
    {
      'dataField': 'deviceId',
      'header': 'Device Id'
    },
    {
      'dataField': 'status',
      'header': 'Status'
    },
    {
      'dataField': 'serialNumber',
      'header': 'Serial Number'
    },
    {
      'dataField': 'currentOwner',
      'header': 'Current Owner'
    }
  ];

  ngOnInit(): void {}
}
