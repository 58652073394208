import { EnergyHubApiService } from './../energy-hub-api/energy-hub-api.service';
import { Injectable, Optional } from '@angular/core';
import { EnergyHubStoreModel, CommmentStoreModel } from '@app/core/model/energy-hub.model';
import { AppLoggerService, AppStore, AppStoreSettings } from '@eucoms/common-components';
import { forkJoin, Observable, of, BehaviorSubject } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { Account } from '@app/core/model/account.model';
import { Device } from '@app/core/model/device.model';
import { Comment } from '@app/core/model/comment.model';
import { FactoryResetReason } from '@app/core/model/factory-reset-reason.model';


export enum EnergyHubAppStoreActions {
  InitializeState = 'initialize_state',
  GetPendingAccounts = 'get_pending_accounts', //accounts started enrollment but don't have an active device yet
  GetEnrolledAccounts = 'get_enrolled_accounts', //fully enrolled
  GetNotEnrolledAccounts = 'get_not_enrolled_accounts', //not enrolled in alerts
  GetAccount = 'get_account',
  GetDevice = 'get_device',
  AssignDevice = 'assign_device',
  EnrollAccount = 'enroll_account',
  UnenrollAccount = 'unenroll_account',
  AddAccount = 'add_account',
  AddDevice = 'add_device',
  SetActiveAccountsTab = 'set_active_accounts_tab',
  FactoryReset = 'factory_reset'
};


@Injectable({
  providedIn: 'root'
})
export class EnergyHubStateService extends AppStore<EnergyHubStoreModel> {
  public static readonly DEFAULT_INITIAL_STATE: EnergyHubStoreModel = {
    account: null,
    pendingAccounts: null,
    enrolledAccounts: null,
    notEnrolledAccounts: null,
    device: null,
  };

  public static readonly DEFAULT_SETTINGS: AppStoreSettings = {
    trackStateHistory: true,
    logStateChanges: true,
    stateSliceSelector: null
  };

  public static readonly COMMENTS_STATE: CommmentStoreModel = {
    comments: null
  };  

  accountNameAndNum = '';
  showAccountNameAndNum: BehaviorSubject<string>;

  constructor(
    private api: EnergyHubApiService,
    @Optional() logger: AppLoggerService
  ) {
    super(EnergyHubStateService.DEFAULT_SETTINGS, logger);

    this.initializeState();

    this.showAccountNameAndNum = new BehaviorSubject(this.accountNameAndNum);

    // this.account$ = this.stateChanged$.pipe(map(state => state.account));

  }

  putAccountNameAndNum(value: string)
  {
    this.showAccountNameAndNum.next(value);
  }

  initializeState(state: EnergyHubStoreModel = EnergyHubStateService.DEFAULT_INITIAL_STATE): void {
    this.setState(state, EnergyHubAppStoreActions.InitializeState);
  }

  getEnergyHubState(): EnergyHubStoreModel {
    return this.getState();
  }

  public getPendingAccounts(reload: boolean = false): Observable<Array<Account>> {
    const state = this.getState();
    if (state.pendingAccounts && reload === false) {
      return of(state.pendingAccounts);
    } else {
      return this.fetchPendingAccounts().pipe(
        map((pendingAccounts: Array<any>) => {
          this.setState({pendingAccounts}, EnergyHubAppStoreActions.GetPendingAccounts);
          return pendingAccounts;
        }),
      );
    }
  }

  private fetchPendingAccounts(): Observable<Array<Account>> {
    return this.api.get<Array<Account>>('accounts/status/pending').pipe(
      publishLast(),
      refCount()
    );
  }

  public getEnrolledAccounts(reload: boolean = false): Observable<Array<Account>> {
    const state = this.getState();
    if (state.enrolledAccounts && reload === false) {
      return of(state.enrolledAccounts);
    } else {
      return this.fetchEnrolledAccounts().pipe(
        map((enrolledAccounts: Array<any>) => {
          this.setState({enrolledAccounts}, EnergyHubAppStoreActions.GetEnrolledAccounts);
          return enrolledAccounts;
        }),
      );
    }
  }

  private fetchEnrolledAccounts(): Observable<Array<Account>> {
    return this.api.get<Array<Account>>('accounts/status/enrolled').pipe(
      publishLast(),
      refCount()
    );
  }

  public getNotEnrolledAccounts(reload: boolean = false): Observable<Array<Account>> {
    const state = this.getState();
    if (state.notEnrolledAccounts && reload === false) {
      return of(state.notEnrolledAccounts);
    } else {
      return this.fetchNotEnrolledAccounts().pipe(
        map((notEnrolledAccounts: Array<any>) => {
          if (notEnrolledAccounts?.length > 0) {
            notEnrolledAccounts = notEnrolledAccounts.slice(0,100);
          }
          this.setState({notEnrolledAccounts}, EnergyHubAppStoreActions.GetNotEnrolledAccounts);
          return notEnrolledAccounts;
        }),
      );
    }
  }

  private fetchNotEnrolledAccounts(): Observable<Array<Account>> {
    return this.api.get<Array<any>>('accounts/status/notenrolled').pipe(
      publishLast(),
      refCount()
    );
  }


  public getAccount(accountNumber: string, reload = false): Observable<Account> {
    const state = this.getState();
    if (state.account?.accountNumber === accountNumber && reload === false) {
      return of(state.account);
    } else {
      return this.fetchAccount(accountNumber).pipe(
        map((account: Account) => {
          this.setState({account}, EnergyHubAppStoreActions.GetAccount);
          return account;
        })
      );
    }
  }

  private fetchAccount(accountNumber:string): Observable<Account> {
    return this.api.get<Account>(`accounts/${accountNumber}`).pipe(
      publishLast(),  // avoid multiple calls for simultaneous subscribers
      refCount()      // auto unsubscribe from multiple subscribers after finishing
    )
  }

  // TODO
  // public addAccount(account: Account) {
  //   return this.api.post('accounts', account).pipe(
  //     switchMap((account: Account) => {
  //       this.setState({account}, EnergyHubAppStoreActions.AddAccount);
  //       return this.fetchAccounts().pipe(
  //         switchMap(_ => {
  //           return this.getAccount(account.accountNumber);
  //         })
  //       )
  //     })
  //   );
  // }

  //#endregion

  //#region Devices
  private fetchDevice(deviceId: string): Observable<Device> {
    return this.api.get<Device>(`devices/${deviceId}`).pipe(
      publishLast(),
      refCount()
    );
  }

  public getDevice(deviceId: string, reload = false): Observable<Device> {
    const state = this.getState();
    if (state.device && state.device.deviceId == deviceId && reload === false) {
      return of(state.device);
    } else {
      return this.fetchDevice(deviceId).pipe(
        map((device: Device) => {
          this.setState({device}, EnergyHubAppStoreActions.GetDevice);
          return device;
        }),
      );
    }
  }
  //#endregion

  assignDevice(account: Account, device: Device, comments: string) {
    const assignRequest = {
      accountNumber: account.accountNumber,
      primaryPhoneNumber: account.primaryPhoneNumber,
      deviceSerialNumber: device.deviceId
    };

    return this.api.post('devices/assign', assignRequest)
      .pipe(
          switchMap(_ => forkJoin({
              account: this.fetchAccount(account.accountNumber),
              device: this.fetchDevice(device.deviceId),
              pendingAccounts: this.fetchPendingAccounts()
            })
          ),
          map(result => {
            this.setState(result, EnergyHubAppStoreActions.AssignDevice);
            return true;
          })
      );
  }

  enrollAccount(account: Account, alertNames: Array<string>) {
    const enrollRequest = {
      accountNumber: account.accountNumber,
      primaryPhoneNumber: account.primaryPhoneNumber,
      alertNames: alertNames
    }
    return this.api.post('accounts/enroll', enrollRequest)
    .pipe(
      switchMap(_ => {
        return forkJoin({
          account: this.fetchAccount(account.accountNumber),
          pendingAccounts: this.fetchPendingAccounts()
        });
      }),
      map(result => {
        this.setState({
          account: result.account,
          pendingAccounts: result.pendingAccounts
        }, EnergyHubAppStoreActions.EnrollAccount);
        this.setState(result, EnergyHubAppStoreActions.EnrollAccount);
        return true;
      })
  );
  }

  unenrollAccount(account: Account) {
    const unenrollRequest = {
      accountNumber: account.accountNumber,
      primaryPhoneNumber: account.primaryPhoneNumber
    }
    return this.api.post('accounts/unenroll', unenrollRequest)
    .pipe(
      switchMap(_ => {
        return forkJoin({
          account: this.fetchAccount(account.accountNumber),
          pendingAccounts: this.fetchPendingAccounts()
        });
      }),
      map(result => {
        this.setState({
          account: result.account,
          pendingAccounts: result.pendingAccounts
        }, EnergyHubAppStoreActions.UnenrollAccount);
        this.setState(result, EnergyHubAppStoreActions.UnenrollAccount);
        return true;
      })
  );
  }

  setActiveAccountsTab(tabIndex: number) {
    return this.setState({ activeAccountsTab: tabIndex }, EnergyHubAppStoreActions.SetActiveAccountsTab)
  }


  sendInitialDeviceConfiguration(accountNumber: string, deviceId: string) {

    if(!(deviceId && accountNumber)) {
      console.log('DeviceId or AccountNumber is not present');
    }

    const initialConfigRequest = {
      AccountNumber: accountNumber
    }

    return this.api.post(`devices/${deviceId}/initialconfig`, initialConfigRequest)
  }


  fetchComments(accountNumber:string): Observable<Array<Comment>> {
    return this.api.get<Array<Comment>>(`accounts/${accountNumber}/comments`)
  }

  addComment(accountNumber: string, payload: Comment) {
    return this.api.post(`accounts/${accountNumber}/comments`, payload).pipe(
      switchMap(_ => {
        return this.fetchComments(accountNumber);
      })
    );
  }

  sendCustomerData(accountNumber: string, deviceId: string) {

    if(!(deviceId && accountNumber)) {
      console.error('DeviceId or AccountNumber is not present');
    }

    const customerDataPayload = {
      AccountNumber: accountNumber,
      includeInitialConfiguration: false, // to exclude initial device configuration fields to be sent along with the message.
    }

    return this.api.post(`devices/${deviceId}/refresh`, customerDataPayload)
  }

  getfactoryResetReason(): Observable<Array<FactoryResetReason>> {
    return this.api.get<Array<FactoryResetReason>>(`factoryresetreasons`);
  }


  factoryReset(accountNumber: string, deviceId: string, firstName: string, lastName: string) {
    const factoryResetPayload = {
      firstName: firstName ?? 'FirstName',
      lastName: lastName ?? 'LastName'
    };

    return this.api.put(`devices/${deviceId}/factoryreset`, factoryResetPayload)
      .pipe(
          switchMap(_ => forkJoin({
              account: this.fetchAccount(accountNumber),
              pendingAccounts: this.fetchPendingAccounts()
            })
          ),
          map(result => {
            this.setState({
              account: result.account,
              device: null,
              pendingAccounts: result.pendingAccounts
            }, EnergyHubAppStoreActions.FactoryReset);
            this.setState(result, EnergyHubAppStoreActions.FactoryReset);
            return true;
          })
      );
  }
}
