import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Account } from '@app/core/model/account.model';
import { Router } from '@angular/router';
import { EnergyHubStateService } from '../../../core/services/energy-hub-state/energy-hub-state.service';
import { EudsModalComponent } from '@eucoms/common-components/design-system';

@Component({
  selector: 'app-accounts-list',
  templateUrl: './accounts-list.component.html',
  styleUrls: ['./accounts-list.component.scss']
})
export class AccountsListComponent implements OnInit {

  @Input() dataSource: Array<Account>;
  @Input() title: string = "Accounts";
  @Input() selectedAccount: string;
  @Output() selectedAccountChange = new EventEmitter<string>();
  @ViewChild('modalNotSaved') modalNotSaved: EudsModalComponent;

  dirtyModal: boolean;
  saveDetailsValue: boolean;
  accountNameAndNum: string;
  accountNumber: string;
  enableSave: boolean = true;

  constructor(private router: Router, private stateService: EnergyHubStateService) {
    this.stateService.showAccountNameAndNum.subscribe(value => {
      this.accountNameAndNum = value;
    });
  }

  ngOnInit(): void {
  }

  columnDefs = [
    {
      'dataField': 'customerName',
      'header': 'Name'
    },
    {
      'dataField': 'registrationDate',
      'header': 'Registration Date'
    },
  ];

  showModal(row: Account) {
    this.accountNumber = row.accountNumber;
    return this.modalNotSaved.showModal({});
  }

  formatAddress(row: Account): string {
    return (Object.assign(new Account(), row)).address;
  }

  isSelected(row: Account) {
    return (this.selectedAccount === row.accountNumber);
  }

  selectRow(row: Account) {
    this.selectedAccount = row.accountNumber;
    this.selectedAccountChange.emit(row.accountNumber);
  }

  getTitle() {
    return `${this.title} (${this.dataSource.length})`;
  }

  saveDirtyModal(modal: EudsModalComponent) {
    modal.hideModal({});
  }

  discardDirtyModal(modal: EudsModalComponent) {
    // this.dirtyModal = false;
    this.router.navigate(['home', this.accountNumber]);
    modal.hideModal({});
  }

}
