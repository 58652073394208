import { AppConfigService } from '@eucoms/common-components';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class BaseResponse
{
    success: boolean;
}

export class SuccessResponse<T> extends BaseResponse
{
    data: T;
}

export class ErrorResponse extends BaseResponse
{
    meta: {
      code: string;
      context: string;
      description: string;
    }
}

@Injectable({
  providedIn: 'root'
})
export class EnergyHubApiService {
  private baseUrl;
  private functionAuthCode;

  constructor(private httpClient: HttpClient,
    private appConfig: AppConfigService) {

      this.baseUrl = this.appConfig.config.liehMiddlewareApiUrl + '/api';
      this.functionAuthCode = appConfig.config.liehMiddlewareApiUrlFunctionCode;
    }

  get<T>(route: string, options?:  {  headers?: HttpHeaders | { [header: string]: string | string[];};
                                      observe?: 'body';
                                      params?: HttpParams | { [param: string]: string | string[]; };
                                      reportProgress?: boolean;
                                      responseType?: 'json';
                                      withCredentials?: boolean;
                                    }) {
    if (options) {
      options.params = this.getParams(options?.params as HttpParams);
    } else {
      options = { params: this.getParams()};
    }
    const url = this.buildUrl(route);

    return this.httpClient.get<BaseResponse>(url, options).pipe(
        map((response: BaseResponse) => {
          return (response as SuccessResponse<T>).data;
        }),
        catchError(this.handleError)
    )
  }

  post(route: string, body: any) {
    const url = this.buildUrl(route);
    return this.httpClient.post(url, body, { params: this.getParams() }).pipe(
      catchError(this.handleError)
    )
  }

  put(route: string, body: any) {
    const url = this.buildUrl(route);
    return this.httpClient.put(url, body, { params: this.getParams() }).pipe(
      catchError(this.handleError)
    )
  }

  delete(route: string, body: any) {
    const url = this.buildUrl(route);
    return this.httpClient.delete(url).pipe(
      catchError(this.handleError)
    )
  }

  private getParams(params: HttpParams = new HttpParams()): HttpParams {
    return params.set("code", this.functionAuthCode);
  }

  private handleError(error: any) {
    console.log('server error:', error);
    if (error.error instanceof Error) {
        const errMessage = error.error.message;
        return throwError(errMessage);
    }
    return throwError(error || 'Server error');
  }

  private buildUrl(route: string) {
    const url = this.baseUrl;
    if (!route.startsWith("/")) {
      route = "/" + route;
    }
    return url + route;
  }
}
