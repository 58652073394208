export class GraphUser
{
    displayName: string;
    givenName: string
    jobTitle: string;
    mail: string;
    surname: string;
    userPrincipalName: string;
    id: string;
}

