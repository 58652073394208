import { EnergyHubApiService } from './../energy-hub-api/energy-hub-api.service';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Device } from '@app/core/model/device.model';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {

  constructor(private api: EnergyHubApiService) { }

  getDevice(serialNumber: string) {
    return this.api.get<Device>(`devices/${serialNumber}`);
  }

  getDevices() {
    return this.api.get<Array<Device>>('devices?details=true');
    //return this.api.get<Array<Device>>('devices');
  }

  addDevice() {
    //TODO
  }
}
