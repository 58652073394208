  <div *ngIf="account.accountNumber else noAccount">
    <table id='account-info' class='euds-table'>
      <!-- <caption>Account Info</caption> -->
      <tr>
        <td>Account number:</td>
        <td>{{account.accountNumber}}</td>
      </tr>
      <tr>
        <td>Customer name:</td>
        <td>{{account.customerName}}</td>
      </tr>
      <tr>
        <td>Primary phone number:</td>
        <td>{{account.primaryPhoneNumber}}</td>
      </tr>
      <tr>
        <td>Mailing address:</td>
        <td>{{formatAddress()}}</td>
      </tr>
      <!-- <tr>
        <td>Eligible for:</td>
        <td>{{formatEligibility()}}</td>
      </tr> -->
      <tr>
        <td>Enrolled in:</td>
        <td>{{formatEnrollment()}}</td>
      </tr>
      <tr *ngIf='account?.currentDevice'>
        <td>Device serial number: </td>
        <td>{{account?.currentDevice?.serialNumber}}</td>
      </tr>
      <tr *ngIf='account?.currentDevice'>
        <td>Device assigned and mailed on:</td>
        <td><span [title]='account?.currentDevice?.assignedDate'>{{formatAssignedDate()}}</span></td>
      </tr>
    </table>

    <div class='action-bar margin-top-2'>

        <app-euds-button type="primary" size="small" *ngIf="shouldShowEnrollButton()"
          label="Alert Enrollments" icon='feather/log-in'
          (clickEvent)="emitAction('enroll')">
        </app-euds-button>

        <app-euds-button type="primary" size="small" *ngIf="shouldShowAssignDeviceButton()" class='margin-left-2'
          label="Assign (and mail) Device" icon='feather/package'
          (clickEvent)="emitAction('assign')">
        </app-euds-button>

        <app-euds-button type="primary" *ngIf="shouldShowUnassignDeviceButton()" class='margin-left-2'
          label="Un-assign Device" size="small"
          (clickEvent)="emitAction('unassign')">
        </app-euds-button>

    </div>
  </div>

  <!-- <pre *ngIf='account'>{{account | json}}</pre> -->


  <ng-template #noAccount>
    <p>No Account Information available</p>
  </ng-template>
