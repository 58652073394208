import { AuthGuard } from './core/services/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
      path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
      canActivate: [
        AuthGuard
      ]
  },
  {
      path: 'vik', loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule),
      canActivate: [
        AuthGuard
      ]
  },
  {
    path: 'dev', loadChildren: () => import('./dev/dev.module').then(m => m.DevModule),
    canActivate: [
      AuthGuard
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
