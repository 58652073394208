import { Device } from "./device.model";

export class Account
{
    accountNumber: string;
    customerName: string
    primaryPhoneNumber: string;
    addressStreet: string;
    addressCity: string;
    addressZipCode: string;
    addressState: string;

    alertEnrollments: Array<AlertEnrollment>;
    // alertEligibility: Array<string>;
    currentDevice: Device;

    get address(): string {
      return `${this.addressStreet}, ${this.addressCity}, ${this.addressState} ${this.addressZipCode}`;
    }

    [index: string]: unknown; // ensure flexible contract

}

export interface AlertEnrollment {
  name:             string;
  registrationDate: Date;
}
