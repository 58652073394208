import { SimpleAppConfigService } from './core/services/simple-app-config.service';
import { SharedModule } from './shared/shared.module';
import { EnergyHubStateService } from './core/services/energy-hub-state/energy-hub-state.service';
import { CommonModule } from '@angular/common';
import { DevicesService } from './core/services/devices/devices.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EudsHeaderSimpleModule, EudsFooterSimpleModule,
  EudsOpcoBrandingModule, EudsCoreServicesModule, EudsNavContainerModule, EudsButtonModule } from '@eucoms/common-components/design-system';
import { EuCoreServicesModule, OpCo, AppConfigService } from '@eucoms/common-components';
import { NO_CONFIG_ENDPOINT, NO_SESSION_ENDPOINT, NO_ALERTS_ENDPOINT } from '@eucoms/common-components';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalInterceptorConfiguration, MsalGuardConfiguration, MsalModule, MsalBroadcastService, MsalGuard, MsalInterceptor,
  MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalRedirectComponent } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '@environments/environment';
import { AuthGuard } from './core/services/auth.guard';

const CoreServicesInitOptions = {
  configEndpoint: NO_CONFIG_ENDPOINT, //doesn't matter as we're overriding AppConfigService
  sessionEndpoint: NO_SESSION_ENDPOINT,
  alertsEndpoint: NO_ALERTS_ENDPOINT,
  // additionalConfig: {
  //   common: {
  //     opCo: OpCo.ComEd
  //   }
  // },
}

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(appConfig: AppConfigService): IPublicClientApplication {
  return new PublicClientApplication({
        auth: {
          clientId: appConfig.config.ssoClientId,
          authority: `${appConfig.config.ssoLoginBaseUrl}/${appConfig.config.ssoTenantId}`,
          redirectUri: `${appConfig.config.liehUIUrl}`,
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE, // set to true for IE 11
        },
        system: {
          loggerOptions: {
            loggerCallback,
            logLevel: LogLevel.Info,
            piiLoggingEnabled: false
          }
        }
      });
}

export function MSALInterceptorConfigFactory(appConfig: AppConfigService): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  if (appConfig.config.graphAuthUrl && appConfig.config.graphAuthUrlScope) {
    //['https://graph.microsoft.com/{tenantId}/v1.0/me', ['user.read']
    protectedResourceMap.set(appConfig.config.graphAuthUrl, [appConfig.config.graphAuthUrlScope]);
  }

  if (appConfig.config.liehMiddlewareApiUrl && appConfig.config.liehMiddlewareApiUrlScope) {
    // ['...-api.azurewebsites.net', ['api://.../access_as_user']]
    protectedResourceMap.set(appConfig.config.liehMiddlewareApiUrl, [appConfig.config.liehMiddlewareApiUrlScope]);
  }

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(appConfig: AppConfigService): MsalGuardConfiguration {
  const ssoLoginScope: string = appConfig.config.ssoLoginScope as string;
  const config = {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: [ ssoLoginScope ],
      prompt: "none"
    }
  } as MsalGuardConfiguration;
  return config;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    EudsHeaderSimpleModule,
    EudsFooterSimpleModule,
    EudsOpcoBrandingModule,
    EudsNavContainerModule,
    EudsCoreServicesModule,
    EudsButtonModule,
    EuCoreServicesModule.forRoot(CoreServicesInitOptions),
    SharedModule,
    MsalModule
  ],
  providers: [
    DevicesService,
    EnergyHubStateService,
    {
      provide: AppConfigService,
      useClass: SimpleAppConfigService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory : MSALInstanceFactory,
      deps: [AppConfigService]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
      deps: [AppConfigService]
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      deps: [AppConfigService]
    },
    MsalService,
    MsalGuard,
    AuthGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
