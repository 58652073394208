<app-euds-data-table class="margin-top-2"
  [showCaption]="true"
  [condensed]="false"
  [striped]="true"
  [selectable]="false"
  [dataSource]="dataSource"
  [columnDefs]="columnDefs">

  <span slot="caption">
    <app-euds-svg [attr.aria-hidden]="true" icon="feather/monitor" size="sm"></app-euds-svg> Devices
  </span>

  <tr app-euds-table-row *ngFor="let row of dataSource">
    <td app-euds-table-cell>
      <a class="euds-link-alt icon-left" [routerLink]="['/dev/devices/' + row.deviceId]">
        <span [innerHtml] = 'row?.deviceId | highlight : searchText'></span>
        <app-euds-svg [attr.aria-hidden]="true" icon="feather/edit" size="sm"></app-euds-svg>
      </a>
    </td>
    <td app-euds-table-cell>
      {{row?.status}}
    </td>
    <td app-euds-table-cell [innerHtml]='row?.serialNumber | highlight: searchText'></td>
    <td app-euds-table-cell>
      <app-euds-svg *ngIf="row?.currentAccount" [attr.aria-hidden]="true" icon="feather/hash" size="sm" style="--stroke-color: #5a626d; line-height: 1rem; vertical-align: middle;"></app-euds-svg>
      <span [innerHtml] = 'row?.currentAccount?.accountNumber | highlight: searchText'></span>
    </td>
  </tr>

</app-euds-data-table>
