<section>
  <app-euds-data-table *ngIf='dataSource'
  [showCaption]="true"
  [condensed]="false"
  [striped]="false"
  [selectable]="false"
  [dataSource]="dataSource"
  [columnDefs]="columnDefs"
  [showCaption]="false">

  <span slot="caption">
    <app-euds-svg [attr.aria-hidden]="true" size="sm"></app-euds-svg><span id='account-list-title'>{{getTitle()}}</span>
  </span>
  
  <tr app-euds-table-row *ngFor="let row of dataSource">
    <td app-euds-table-cell>
      <span class="left-column">
        <a class="cust-name" (click)="selectRow(row)">
          {{row.customerName.toLowerCase()}}
        </a>
      </span>
    </td>
    <td class="right-column" app-euds-table-cell [innerHtml]='row?.alertEnrollments[0]?.registrationDate | date:"MM/dd/yyyy"'></td>
  </tr>
</app-euds-data-table>
<app-euds-modal #modalNotSaved size="small" heading="Would you like to save your changes?" [isCloseable]="true" alignHeading="left">
  <p>Your recent edits to {{accountNameAndNum}} have not been saved. Would you like to save them before viewing a new account?</p>
  <div style="text-align: center;">
      <app-euds-button type="primary" class="btn-modal-save" size="small" label="Discard Changes" (clickEvent)="discardDirtyModal(modalNotSaved)" ></app-euds-button>
  </div>
</app-euds-modal>
</section>
