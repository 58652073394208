import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { MsalBroadcastService, MsalCustomNavigationClient, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, InteractionStatus, AccountInfo, AuthenticationResult, RedirectRequest, EventType } from '@azure/msal-browser';
import { AppConfigService, OpCo } from '@eucoms/common-components';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { GraphUserService } from './core/services/graph-user/graph-user.service';
import { GraphUser } from './core/model/graph-user.model';

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  opCo: OpCo;
  profile!: ProfileType;
  account: AccountInfo;
  loggedIn = false;
  homeUrl: string;
  graphProfile: GraphUser;
  private readonly _destroying$ = new Subject<void>();



  constructor(private http: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private appConfig: AppConfigService,
    private router: Router,
    private location: Location,
    private graphUserService: GraphUserService) {

      const customNavigationClient = new MsalCustomNavigationClient(this.authService, this.router, this.location);
      this.authService.instance.setNavigationClient(customNavigationClient);
      this.opCo = this.appConfig.config.opCo;
      this.homeUrl = window.location.origin;
  }

  ngOnInit() {

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: (value) => {
          if (!this.loggedIn && value === InteractionStatus.None) {
            this.setLoggedIn();
          }
        }
      });

    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      takeUntil(this._destroying$)
    )
    .subscribe({
      next: (result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.account = payload.account;
        this.authService.instance.setActiveAccount(payload.account);
        this.loggedIn = true;        
      }
    });

    this.setLoggedIn();

  }

  private setLoggedIn() {
    let accounts = this.authService.instance.getAllAccounts();
    this.loggedIn = accounts.length > 0;
    this.graphUserService.getCurrentUser().subscribe(result => {
      console.log('Graph User Details', result);
      this.graphProfile = Object.assign(new GraphUser(), result);
    });

    if (this.loggedIn) {
      this.account = accounts[0];
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logout();
  }

  destroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


}
