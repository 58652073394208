import { DateTime } from 'luxon';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Account } from '@app/core/model/account.model';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent implements OnInit {

  @Input() account: Account;
  @Input() actionInProgress: boolean;
  @Output() action = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  formatAddress() {
    if (this.account) {
      return Object.assign(new Account(), this.account).address;
    }
  }

  // formatEligibility() {
  //   let text = "";

  //   if (this.account?.alertEligibility) {
  //     for (const alert of this.account.alertEligibility) {
  //       if (alert === "WeatherAlert") {
  //         // do nothing, currently all customers are eligible for Weather Alert and it's ok to ignore it
  //       } else {
  //         if (text.length > 0) text += ", ";
  //         text += this.formatAlert(alert);
  //       }
  //     }
  //   }
  //   return text;
  // }

  formatAlert(alert:string) {
    switch (alert) {
      case "WeatherAlert": return "Weather Alert";
      case "BudgetAlert": return "Budget Alert";
      case "HourlyPricingAlert": return "Hourly Pricing Alert";
    }
  }

  formatEnrollment() {
    let text = "";

    if (this.account?.alertEnrollments) {
      for (const alert of this.account.alertEnrollments) {
        if (alert.name === "WeatherAlert") {
          // do nothing, currently all customers are eligible for Weather Alert and it's ok to ignore it
        } else {
          if (text.length > 0) text += ", ";
          text += this.formatAlert(alert.name);
        }
      }
    }
    return text;
  }


  shouldShowEnrollButton() {
    return true;
  }
  shouldShowAssignDeviceButton() {
    return this.account?.alertEnrollments?.length > 0;
  }
  shouldShowUnassignDeviceButton() {
    return !!(this.account?.currentDevice?.assignedDate);
  }

  emitAction(action: string) {
    this.action.emit(action);
  }

  formatAssignedDate() {
    if (this.account) {
      return DateTime.fromISO(this.account.currentDevice?.assignedDate as string).toLocaleString(DateTime.DATE_MED);
    }
  }


}
