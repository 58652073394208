import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightDirective } from './directives/highlight/highlight.directive';
import { HighlightPipe } from './pipes/highlight/highlight.pipe';
import { DevicesListComponent } from './components/devices-list/devices-list.component';
import { EudsDataTableModule, EudsSvgModule, EudsButtonModule, EudsModalModule, EudsCardModule } from '@eucoms/common-components/design-system';
import { AccountsListComponent } from './components/accounts-list/accounts-list.component';
import { AccountInfoComponent } from './components/account-info/account-info.component';
import { AccountLookupComponent } from './components/account-lookup/account-lookup.component';
import { DeviceLookupComponent } from './components/device-lookup/device-lookup.component';
import { DeviceInfoComponent } from './components/device-info/device-info.component';



@NgModule({
  declarations: [
    HighlightDirective,
    HighlightPipe,
    DevicesListComponent,
    AccountsListComponent,
    AccountInfoComponent,
    AccountLookupComponent,
    DeviceLookupComponent,
    DeviceInfoComponent
  ],
  imports: [
    CommonModule,
    EudsDataTableModule,
    EudsSvgModule,
    RouterModule,
    EudsButtonModule,
    EudsModalModule,
    EudsCardModule
  ],
  exports: [
    HighlightDirective,
    HighlightPipe,
    DevicesListComponent,
    AccountsListComponent,
    AccountInfoComponent,
    AccountLookupComponent,
    DeviceLookupComponent,
    DeviceInfoComponent
  ]
})
export class SharedModule { }
